import dynamic from 'next/dynamic';

const HomePage = dynamic(() => import('../Home'));

const PostDetail = dynamic(() => import('../News/Detail'));

const PostCategory = dynamic(() => import('../News'));

const ProductCategory = dynamic(() => import('../Category'));

const ProductDetail = dynamic(() => import('../Product/detail'));

export default function WrapPage(props) {
    if (props.type === 'news_category') {
        return <PostCategory {...props.data}/>;
    }
    if (props.type === 'news') {
        return <PostDetail {...props.data} webViewMobile={props.webViewMobile || false}/>;
    }
    if (props.type === 'products') {
        return <ProductDetail {...props.data}/>;
    }
    if (props.type === 'products_category') {
        return <ProductCategory {...props.data}/>;
    }

    return <HomePage {...(props.data || {})}/>;
}